"use strict";
var _NCMEC_missingKids;
Object.defineProperty(exports, "__esModule", { value: true });
exports.NCMEC = void 0;
const tslib_1 = require("tslib");
const missing_kids_1 = require("./missing-kids");
class NCMEC {
    constructor({ xmlParserOptions } = {}) {
        _NCMEC_missingKids.set(this, void 0);
        tslib_1.__classPrivateFieldSet(this, _NCMEC_missingKids, (0, missing_kids_1.createMissingKidsClient)(xmlParserOptions), "f");
    }
    getMissingChildrenClient() {
        return tslib_1.__classPrivateFieldGet(this, _NCMEC_missingKids, "f");
    }
    /**
     * Retrieves missing child alert cases
     */
    retrieveMissingChildrenAlerts(...args) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return tslib_1.__classPrivateFieldGet(this, _NCMEC_missingKids, "f").retrieveAlerts(...args);
        });
    }
    /**
     * Retrieves missing child cases in a state
     */
    retrieveMissingChildrenCases(...args) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return tslib_1.__classPrivateFieldGet(this, _NCMEC_missingKids, "f").retrieveCases(...args);
        });
    }
    /**
     * Retrieves the profile of the missing child (in the form of a poster)
     */
    retrieveMissingChildPoster(...args) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return tslib_1.__classPrivateFieldGet(this, _NCMEC_missingKids, "f").retrievePoster(...args);
        });
    }
    /**
     * Retrieves the photo of the missing child
     */
    retrieveMissingChildPhoto(...args) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return tslib_1.__classPrivateFieldGet(this, _NCMEC_missingKids, "f").retrievePhoto(...args);
        });
    }
}
exports.NCMEC = NCMEC;
_NCMEC_missingKids = new WeakMap();
