import React from 'react'
import { Draft, produce } from 'immer'
import {
  Box,
  GridItem,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { NCMEC } from 'findthosemissing'
import type { RssResponseChannelItem } from 'findthosemissing'

type Action =
  | { type: 'fetched'; items: RssResponseChannelItem[] }
  | { type: 'fetching' }
  | { type: 'fetch-failed'; error: Error }

type State = typeof initialState

const initialState = {
  fetching: false,
  fetched: true,
  error: null as null | Error,
  items: [] as RssResponseChannelItem[],
}

function reducer(state: State, action: Action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'fetching':
        return void Object.assign(draft, initialState, { fetching: true })
      case 'fetched':
        return void Object.assign(draft, initialState, {
          fetched: true,
          items: action.items,
        })
      case 'fetch-failed':
        return void Object.assign(draft, initialState, { error: action.error })
    }
  })
}

const HomePage = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { current: ncmec } = React.useRef<NCMEC>(new NCMEC())

  React.useEffect(() => {
    dispatch({ type: 'fetching' })
    ncmec
      .retrieveMissingChildrenCases('CA')
      .then((results) => dispatch({ type: 'fetched', items: results }))
      .catch((error) => dispatch({ type: 'fetch-failed', error }))
  }, [])

  React.useEffect(() => {
    console.log(state)
  }, [state])

  return (
    <Box flexGrow={1} h='100%' p={3}>
      {state.fetching ? (
        <Text>Retrieving results</Text>
      ) : state.error ? (
        <Box>
          <Text color='red.400'>
            {state?.error.name}: {state?.error.message}
          </Text>
        </Box>
      ) : null}
      <SimpleGrid columns={[1, 3, 4, 4, 5]} gap={3}>
        {state.items.map((item) => {
          return (
            <GridItem key={item.link}>
              <Box borderRadius={6}>
                <Link href={item.link} target='_blank'>
                  <Image w='full' h='full' src={item.enclosure?.url} />
                </Link>
              </Box>
              <Box mt={3}>
                <Text fontSize='sm'>{item.title}</Text>
                <Text fontSize='sm'>{item.pubDate}</Text>
              </Box>
            </GridItem>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}

export default HomePage
